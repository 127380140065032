<template>
  <v-data-table
    :loading="isLoading"
    :headers="headers"
    :items="items"
    :server-items-length="totalData"
    :options.sync="pagination"
    :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
  />
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  defaultPagination,
  dateFormat,
} from '@/helper/commonHelpers';

export default {
  name: 'table-detail-order',
  props: {
    shipmentId: Number,
    dialog: Boolean,
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: 'NO',
          class: 'white--text primary',
          value: 'no',
          sortable: false,
          width: '5%',
        },
        {
          text: 'SKU',
          class: 'white--text primary',
          value: 'skuItem',
          width: '10%',
        },
        {
          text: 'Nama Barang',
          class: 'white--text primary',
          value: 'itemName',
          width: '15%',
        },
        {
          text: 'QTY',
          class: 'white--text primary',
          value: 'qty',
          width: '10%',
        },
        {
          text: 'Satuan',
          class: 'white--text primary',
          value: 'unit',
          width: '10%',
        },
        {
          text: 'PO',
          class: 'white--text primary',
          value: 'poDocumentNo',
          width: '10%',
        },
        {
          text: 'SO',
          class: 'white--text primary',
          value: 'soDocumentNo',
          width: '10%',
        },
        {
          text: 'SJ',
          class: 'white--text primary',
          value: 'travelDocumentNo',
          width: '15%',
        },
        {
          text: 'Tanggal SJ',
          class: 'white--text primary',
          value: 'travelDocumentDate',
          width: '25%',
        },
      ],
      items: [],
      totalData: 0,
      pagination: defaultPagination(),
    };
  },
  watch: {
    pagination: {
      handler() {
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
    dialog(newVal) {
      if (newVal) {
        this.fetchData();
      }
    },
  },
  methods: {
    async fetchData() {
      try {
        const {
          page, itemsPerPage, sortBy, sortDesc,
        } = this.pagination;
        const filter = skipEmptyStringObject({
          page: page - 1,
          size: itemsPerPage,
          sort: handleSortBy({ sortBy, sortDesc }),
        });
        this.isLoading = true;
        const result = await this.$_services.order.getDetailOrderUploaded({ shipmentId: this.shipmentId, filter });
        this.items = result.data.contents.map((i, index) => ({
          ...i,
          no: (page - 1) * itemsPerPage + index + 1,
          travelDocumentDate: dateFormat(i.travelDocumentDate),
        }));
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};

</script>
